@use "./scss_modules/ezentrum_modules/form_validator";
@use "ezentrum_fonts";

@mixin flex-justify-content($just) {
	$just: space-between !default;
	-webkit-justify-content: $just;
	@if $just == flex-start {
		-ms-flex-pack: start;
	} @else if $just == flex-end {
		-ms-flex-pack: end;
	} @else if $just == center {
		-ms-flex-pack: center;
	} @else if $just == space-between {
		-ms-flex-pack: justify;
	} @else if $just == space-around {
		-ms-flex-pack: distribute;
	} @else if $align == space-evenly {
		-ms-flex-pack: justify;
	}
	justify-content: $just;
}

// primary
$main-primary: #EB7E2D;
$main-primary-dark: darken($main-primary, 10);
$main-primary-darker: darken($main-primary, 20);
$main-primary-light: lighten($main-primary, 55);
// secondary
$main-secondary: #222222;
$main-secondary-dark: darken($main-secondary, 10);
$main-secondary-darker: darken($main-secondary, 20);
// clean white
$main-bgc-primary: white;
$main-bgc-primary-dark: darken($main-bgc-primary, 10);
$main-bgc-primary-darker: darken($main-bgc-primary, 20);
// light grey
$main-bgc-secondary: #222222;
$main-bgc-secondary-dark: darken($main-bgc-secondary, 10);
$main-bgc-secondary-darker: darken($main-bgc-secondary, 20);
// black
$main-font-color-primary: #222222;
$main-font-color-primary-hover: $main-secondary;
// grey
$main-font-color-secondary: black;
$main-font-color-secondary-hover: $main-primary;
// border-radius global
$border-radius: 0;
$global-radius: 0;
$primary-color: #dc6a15;
$secondary-color: #666666;
$global-padding: 0.9375rem;
$global-margin: 0.9375rem;
@import "../../../../modules_css/all_libs.scss";
@import "../../../../modules_css/all_modules";

/* ##### start: LAYOUT ##### */

html,
body {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 100%;
    line-height: 1.5;
    color: #5C6B80;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    min-height: 100%;
    height: 100%;
    background-color: white;
}

fieldset {
    display: none;
}

.register_fields {
    display: block;
}

h1,
h2,
h3,
h4,
h5 {
    font-size: 0.9em;
    font-weight: bold;
}

a {
    color: $main-primary;
}

.input-group {
    margin-bottom: 0px;
}

input::-ms-clear {
    display: none;
}

.strong {
    font-weight: bold;
}

.alert {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid;
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}

.alert.success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert.danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.alert.warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.alert.info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}

.copyright {
    border-top: 1px solid #959595;
    margin-bottom: 3px;
    font-size: 14px;
    font-weight: 700;
    color: white;
}

.exit-off-canvas {
    background: rgba(0, 0, 0, 0) !important;
    z-index: -1 !important;
}

.move-right .exit-off-canvas {
    text-align: right;
    padding-right: 10px;
    padding-top: 10px;
}

.text-center {
    text-align: center;
}

#admin_labels {
    background-color: #eee;
    border: 1px solid lightgray;
    border-radius: 4px;
    margin: 0px;
    list-style: none;
    ul {
        list-style: none;
        margin: 0px;
        margin-left: 1em;
    }
    #admin_labels_list {
        max-height: 600px;
        max-width: 100%;
        overflow-y: scroll;
        overflow-x: auto;
        li,
        li label {
            background-color: transparent;
            color: black;
            font-size: 18px;
            padding: 0px 5px;
        }
        .changed {
            color: darkgreen;
        }
    }
    .label-radio,
    .group-radio {
        display: none;
    }
    .label-radio:not(:checked)+.label-content,
    .group-radio:not(:checked)+.group-content {
        display: none;
    }
    .label-content {
        padding: 10px;
        padding-top: 20px;
    }
}

#off_canvas_menu {
    background-color: black;
}

#off_canvas_content {
    margin-top: 40px;
    .accordion-navigation {
        >a {
            padding: 0;
            color: #EB7E2D;
            background: none;
        }
        >.content.active {
            padding: 0;
            background: none;
        }
    }
}

#bars_icon {
    font-size: 40px;
}

#close_icon {
    font-size: 30px;
    margin-left: 5px;
}

.header {
    position: fixed;
    width: 100%;
    background-color: #7d7d7d;
    z-index: 999;
    #sub-menu-header {
        .sub-menu-item,
        .sub-menu-link {
            font-size: 14px;
            font-weight: 400;
            color: #E3E3E3;
            margin-right: 5px;
        }
        .sub-menu-link:hover {
            color: #ffffff;
        }
        .sub-menu-link.user-info {
            font-size: 16px !important;
            color: white !important;
        }
        .language img {
            padding: 0.4em 0 0 0.8em;
        }
        #first_header {
            #shopping_cart_header_area {
                padding: 0;
                @media screen and (min-width:1025px) {
                    padding: 0;
                    text-align: center !important;
                }
            }
            #autocomplete_area {
                @media screen and (max-width:640px) {
                    margin-top:7px;
                }
                @media screen and (min-width:1025px)  {
                    margin-top:7px;
                }
            }
        }
    }
    #top_navi {
        margin: 0;
        padding: 0;
        background-color: #7d7d7d;
        .logo.neb {
            padding-right: 0.4em;
        }
        .logo.neb img {
            padding-bottom: 5px;
            max-height: 82px;
        }
        div.logo {
            margin-top: 1em;
        }
        div.logo img {
            margin-left: 0.4em;
            margin-right: 0.4em;
        }
    }
    .uber-menu-header {
        border-bottom: 5px solid $main-primary;
        a {
            color: white;
        }
        ul.uber-menu {
            margin: 0;
            width: 100%;
            min-height: 56px;
            list-style-type: none;
            margin-top: 26px;
            display: flex !important;
            justify-content: space-around;
        }
        ul.uber-menu button.secondary,
        ul.uber-menu .button.secondary {}
        ul.uber-menu button.secondary:hover,
        ul.uber-menu .button.secondary:hover {
            background-color: #8c8c8c;
        }
        ul.uber-menu li {
            position: relative;
            float: left;
            margin: 0;
            padding: 0;
            height: 100%;
            transition: background-color 0.2s ease-in-out 0s !important;
            a {
                display: block;
                padding: 0.2em 0.5em;
                width: 100%;
                height: 100%;
                font-size: 16px;
            }
            a.dropdown {
                background-repeat: no-repeat;
                background-position: right top;
            }
        }
        ul.uber-menu li.active,
        ul.uber-menu li:hover {
            background-color: $main-primary;
        }
        ul.uber-menu li.logo:hover {
            background-color: transparent;
        }
        ul.uber-menu li ul {
            display: none;
            position: absolute;
            top: 56px;
            margin: 0;
            width: 30rem;
            background-color: #E1E1E1;
            list-style-type: none;
            overflow: hidden;
            border-top: 5px solid $main-primary;
            z-index: 1000;
            width: 550px !important;
        }
        ul.uber-menu li ul.left-produkte {
            left: 0rem;
            width: 40rem;
        }
        ul.uber-menu li ul.left-produkte li {
            width: 50%;
        }
        ul.uber-menu li ul.left-produkte li a {
            font-size: 14px;
            font-weight: 700;
            padding: 1em 0.5em;
        }
        ul.uber-menu li ul.left-produkte .row.collapse a {
            margin: 0;
            padding: 0.2rem;
        }
        ul.uber-menu li ul hr {
            border-color: #999;
        }
        ul.uber-menu li ul img.logo {
            max-height: 50px;
        }
        ul.uber-menu li ul img.logo.cst {
            margin-top: 15px;
            max-height: 50px;
        }
        ul.uber-menu li:hover ul {
            display: block;
        }
        ul.uber-menu li ul.left {
            left: 0;
        }
        ul.uber-menu li ul.right {
            right: 0;
        }
        ul.uber-menu li:hover ul li:hover,
        ul.uber-menu li:hover ul li a,
        ul.uber-menu li:hover ul li:hover a {
            color: #7d7d7d;
            background: none;
        }
        ul.uber-menu li:hover ul li a:hover,
        ul.uber-menu li:hover ul li:hover a:hover {
            color: #666;
        }
    }
}

@media only screen and (min-width: 40em) and (max-width: 64em) {
    .uber-menu-header ul.uber-menu li ul.left-produkte {
        left: 0;
        width: 50rem;
    }
}

#small-menu,
#small-menu ul {
    list-style: none;
}

#small-menu li a:hover {
    color: #666;
}

#breadcrumb-header {
    height: 26px;
    background-image: url("../images/subnavi-bg.gif");
    border-bottom: 1px solid #D3D3D3;
}

.f-dropdown#cart_preview {
    max-height: 75vh;
    overflow: scroll;
}

#content_wrap {
    margin-top: 12rem;
    min-height: 600px;
}

@media only screen and (max-width: 64em) {
    .header {
        position: relative;
    }
    #top_navi .logo.neb img {
        max-height: 50px;
    }
    #content_wrap {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 39.9375em) {
    #content_wrap {
        margin-top: 1em;
    }
    #first_header {
        margin-bottom: 5px;
    }
}

#legende {
    clear: both;
    margin-top: 4em;
}

#legende ul {
    list-style-type: none;
}

#footer {
    min-height: 250px;
    width: 100%;
    margin-top: 2em;
    padding: 0px 15px;
    clear: both;
    background-color: #7D7D7D;
    color: #e3e3e3;
    border-top: 5px solid $main-primary;
}

#footer h4 {
    border: medium none;
    margin-bottom: 10px;
    font-weight: normal;
    font-size: 16px;
    color: $main-primary;
}

#footer ul {
    font-size: 0.9em;
    list-style-type: none;
    margin: 0px;
}

#footer a {
    color: #e3e3e3;
}

#footer a:hover {
    color: #fff;
}

#inner-footer {
    margin-top: 1.6em;
}

#side_navi {
    padding: 0;
    background-image: url("../images/bg.gif");
    ul {
        margin: 0;
        list-style-type: none;
        font-size: 0.9em;
        line-height: 1.2em;
        li.level-1 {
            font-weight: bold;
            color: $main-primary;
            border-top: 2px solid #fff;
        }
        li.level-1 a,
        li.level-1 span {
            display: block;
            padding: 0.2em 0.6em;
            width: 100%;
        }
        li.level-1 a:hover {
            color: #ccc;
            background-color: $main-primary;
        }
        li.level-1.selected a {
            color: #ccc;
        }
        li.level-1 .selected {
            background-color: $main-primary;
            color: #ccc;
        }
        li.level-2 {
            font-size: 0.9em;
        }
        li.level-2 a {
            display: block;
            padding: 0.2em 0.6em;
            width: 100%;
            color: #666;
            font-size: 12.96px;
            font-weight: 400;
        }
        li.level-2 a:hover,
        li.level-2 .selected {
            color: $main-primary;
            background-color: unset;
        }
        li.level-3 {
            font-size: 0.9em;
        }
        li.level-3 a {
            display: block;
            width: 100%;
            color: #666;
        }
        li.level-3 a:hover,
        li.level-3 .selected {
            color: $main-primary;
            background-color: unset;
        }
        li.level-4 {
            font-size: 0.9em;
        }
        li.level-4 a {
            display: block;
            width: 100%;
            color: #666;
        }
        li.level-4 a:hover,
        li.level-4 .selected {
            color: $main-primary;
            background-color: unset;
        }
    }
}

#right_column {
    border-left: 1px solid #666;
}

#right_column .legende ul {
    list-style-type: none;
    margin-left: 0;
}

#right_column .legende ul li,
#right_column .legende p {
    font-size: 0.9em;
}


/* ##### stop: LAYOUT ##### */


/* ##### start: Preisdarstellung ##### */

.table_sonderpreis {
    color: #c00;
}

.table_grundpreis {
    text-decoration: line-through;
    font-size: 0.7em;
    font-weight: normal;
}


/* ##### stop: Preisdarstellung ##### */


/* ##### start: LIST ITEMS ##### */

.list-item {
    padding: 10px 0px;
    margin: 0 0px !important;
    h2 {
        margin-bottom: 0.1rem;
    }
    .amount {
        padding: 0;
        .prefix,
        .postfix {
            height: 1.5rem;
            line-height: 1.2rem;
            border-color: #ccc;
            background-color: #efefef;
            color: #666;
            font-weight: bold;
            padding: 0px;
        }
        input {
            height: 1.5rem;
            margin-bottom: 0.3rem;
            padding: 0.2rem 0.5rem;
            background-color: #fafafa;
            text-align: right;
        }
        .submit {
            margin: 0px !important;
            background-color: #7D7D7D !important;
            color: white;
        }
    }
    .icons {
        background-color: #eee;
    }
    .price {
        font-size: 1.1rem;
        font-weight: bold;
        text-align: right;
        .small {
            font-size: 0.8em;
        }
    }
    button {
        margin: 0rem;
    }
    .button {
        margin-bottom: 0.5rem;
        background-color: #7D7D7D;
        color: #fff;
    }
    .button:hover {
        background-color: #999;
        color: #fff;
    }
}

.list-item:not(:last-child) {
    border-bottom: 1px solid lightgray;
}


/* ##### stop: LIST ITEMS ##### */


/* ##### start: FORM FIELDS ##### */

label.register {width:calc(100% - 20px);}
input[type=text].register {width:20px;}

/* ##### stop: FORM FIELDS ##### */


/* ##### start: PAGES ##### */

#artikel_direkt {}

#bestellen_erfolg {}

#index {}

#kasse {}

#lieferanschrift {}

#listing {
    .small-divider {
        margin: 0.1em 0;
    }
}

#merkliste {}

#merkliste_login {}

#merkliste_reg {}

#warenkorb {}

#doppelbesteller_table {
    width: 100%;
    .small-button {
        padding: 5px;
        margin: 0;
        font-size: 14px;
        font-weight: 600;
    }
    thead {
        tr {
            th {
                text-align: center;
            }
        }
    }
    tbody {
        tr {
            td {
                text-align: center;
            }
        }
    }
}


/* ##### stop: PAGES ##### */


/* ##### start: MODULES ##### */


/* ********** Login ********** */

#login_tabs {
    @include display-flex;
    justify-content: center;
    border: 1px solid lightgray;
    .tab-title {
        width: 100%;
        text-align: center;
        a {
            background-color: #bcbbbb;
            outline: none;
        }
    }
    .tab-title.active a {
        background-color: $main-primary;
    }
}


/* ********** Autocomplete ********** */

[hidden]:not([data-ez-open="true"]) {
    display: none;
}

.primary_color {
    color: #5C6B80;
}

.secondary_color {
    color: black;
}

.category_line {
    font-size: 15px;
    background: $main-primary;
    padding: 10px;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}

.button.radius {
    border-radius: 4px;
}


#search_input_container {
    background-color: white;
    margin-top: 5px;
    border-radius: $border-radius;
    width: 200px;
    position: relative;
    margin: auto;
    @media screen and (max-width: 1024px) {
        position: absolute;
        left: -25%;
        top: 34px;
        z-index: 1;
    }
    @media screen and (max-width: 640px) {
        position: sticky;
        z-index: 1;
        width: 80%;
        margin: auto;
    }
    #search_input {
        background-color: rgb(242, 242, 242);
        margin-top: 0px;
        margin-bottom: 0px;
        height: 30px;
        width: 232px;
        position: relative;
        &::placeholder { color: rgb(153, 153, 153); }
        @media screen and (max-width: 640px) { width: 100%; }
    }
    #search_execute {
        display: block;
        height: 22px;
        position: absolute;
        right: 4px;
        width: 23px;
        background: rgba(0, 0, 0, 0) url(../images/download.gif) no-repeat scroll 0 0;
        @media screen and (max-width: 640px) { left: auto; }
    }

    #search_output,
    [data-ez-search-output] {
        position: absolute;
        min-width: 500px;
        background-color: white;
        margin: 0;
        margin-top: 40px;
        z-index: 100;
        padding: 10px;
        border-radius: 4px;
        -webkit-box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.56);
        -moz-box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.56);
        box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.56);
        overflow: auto;
        list-style-type: none;
        top: 40px;
        display: none;
        margin: 0px;
        @media screen and (max-width: 1024px) { left: -60%; }
        &[data-ez-open="true"] {
            display: block;
            @media screen and (max-width: 640px) {
                position: absolute;
                min-width: unset !important;
                left: 0;
                right: 10px;
                top: 40px;
                width: 100%;
                overflow-y: scroll;
                max-height: 60vh;
            }
        }
        li {
            list-style: none;
            padding: 5px;
            :not(:last-child) {
                margin-bottom: 5px;
            }
            .search_headline {
                border-bottom: 1px solid #D3D3D3;
                font-size: 1rem;
            }
            p {
                margin-bottom: 0;
                font-size: 0.8rem;
            }
            h3 { margin: 0; }
            &.search_item {
                font-size: 16px;
                border-radius: 4px;
                display: flex;
                a .image {
                    max-height: 100px;
                    align-self: center;
                }
                .to_shopping_cart_button {
                    padding: 5px 20px;
                    border-radius: 5px;
                    font-size: 20px;
                    margin-top: 30px;
                }
                .productname { font-size: 0.9rem; }
                &:hover {
                    background-color: #D3D3D3;

                }
                &.product {
                    i {
                        font-size: 25px;
                        color: $main-primary;
                    }
                    .productname {
                        padding-left: 20px;
                    }
                }
            }
        }
        li[data-search-item-active="true"] {
            background-color: lightgray;
        }
        #ez_wp_line {
            margin: 5px 0;
            border-color: black;
        }
        a,
        a:active,
        a:link,
        label {
            display: flex;
            text-decoration: underline;
            width: 100%;
            color: #5C6B80;
            &:hover {
                color: $main-primary;
            }
        }
    }
    .search_icon {
        margin-right: 7px;
        padding: 7px 0px;
    }
    .search-btn.left {
        left: 208px;
        text-indent: -9999px;
        top: 5px;
        z-index: 99999;
        border: none !important;
    }
}

#second_nav {
    .og_nav {
        position: relative;
        background-color:#eee;
        padding: 10px;
        width: 49%;
        height: 3rem;
        float: left;
        border: 1px solid lightgray;
        border-radius: 0;
        margin-top: 2%;
        margin-bottom: 2%;
        @include play-animation( listing-scale, 1s, false );
        img {
            position: absolute;
            top: 16px;
            right: 10px;
            z-index: 1;
            max-height: 85%;
        }
        h3 {
            font-weight: bold;
            color: $main-primary;
        }
        color: $main-primary;
        a {
            color: $main-primary;
        }
        :hover {
            color: #dc6a15;
        }
        a:hover {
            color: #dc6a15;
        }
    }
    .og_nav:nth-child(odd) {
        margin-right: 2%;
    }
    
    @media screen and ( max-width: 800px ) {
        .og_nav {
            width: 100%;
        }
    }
}
#second_nav_b {
	a.selected {
		h3 {
            color: #dc6a15;
		}
	}
}

//********** Search **********//
#tabs {
    #orderby_select {
        margin: 0;
    }
    #range_slider_price .ui-slider-handle .tip {
        background-color: white;
        color: $main-primary;
        border: 1px solid $main-primary;
    }
    #range_slider_price .ui-slider-range {
        background: $main-primary;
    }
    #filter_brand_input {
        border: 1px solid lightgray;
        background-color: white;
    }
    #filter_brand_content {
        input[type="checkbox"] {
            margin: 0px;
        }
    }
    .ui-tabs-nav {
        border: 1px solid #bcbbbb;
        padding: 0;
        margin-bottom: 15px;
        .ui-tabs-nav .ui-tabs-tab.ui-state-default {
            background-color: white;
        }
    }
    [data-name-id="filter_toggle_tag"],
    [data-name-id="filter_toggle_brand"] {
        display: none;
    }
    #recommendations_box {
        margin-top: 15px;
        margin-bottom: 15px;
        background-color: #d3d3d3;
        padding: 10px;
        .tab_public_element {
            .content {
                border-top: none;
                background-color: rgba(255, 255, 255, 0.5);
            }
            .information_item {
                margin-bottom: 10px;
            }
        }
    }
    #filters_box {
        .tab_public_element {
            .callout {
                padding:0.5rem;
                background-color:#dedede;
                border:none;
                margin: 0 0 1rem 0;
                border-radius: 0px;
                position: relative;
                color: #0a0a0a;
                > .row.headline {
                    margin-left: -0.5rem;
                    margin-right: -0.5rem;
                    font-weight:500;
                    .filtericon-left {
                        margin-right:10px;
                        width:20px;
                        height:20px;
                        &.icon-tag {}
                        &.icon-tags {}
                        &.icon-trademark {
                            margin-top:-4px;
                        }
                    }
                }
                .filter_icon {width:20px;height:20px;}
            }
            .headline span .icon_plus,
            .headline span .icon_minus {width:20px;height:20px;}
            .row.collapse.filter_brand {padding-left:0.9375rem;padding-right:0.9375rem;}
        }
        #ez_selected_filters {
            &.hidden {
                display:none;
            }
            @include display-flex;
            flex-flow:row wrap;
            @include flex-justify-content(flex-start);
            margin-right:-0.5rem;
            .ez-selected-filter {
                margin:0 0.5rem 0.5rem 0;
                cursor:pointer;
                .allefilter {
                    font-weight:500;
                    color:#c00;
                }
            }
        }
    }
    .ez_filtercontent {
        padding-bottom:0.2rem;
        @include display-flex;
        flex-flow:row wrap;
        @include flex-justify-content(space-between);
        border-top:none;
        #filter_tag_content {
            width:100%;
            @include display-flex;
            flex-flow:row wrap;
            @include flex-justify-content(space-between);
        }
        .ez_filterbox {
            margin:.25rem;
            padding:0 0.9375rem;
            flex:1 0 200px;
            background-color: rgba(255,255,255,.5);
            &.spacer {
                height:1px;
                background-color:transparent;
            }
            .ez_filterheadline,
            .ez_filterlist .ez_filter {
                margin-bottom:0.2rem;
                label {
                    @include display-flex;
                    flex-flow:row wrap;
                    @include flex-justify-content(space-between);
                    input[type="checkbox"] {
                        display:none;
                    }
                    i.fi-x {
                        display:none;
                    }
                    .checkbox_label {
                        padding-bottom:4px;
                        width:100%;
                        word-wrap: break-word;
                        -moz-hyphens: auto;
                        -o-hyphens: auto;
                        -webkit-hyphens: auto;
                        -ms-hyphens: auto;
                        hyphens: auto;
                        cursor:pointer;
                    }
                    input[type="checkbox"]:disabled ~ .checkbox_label {
                        color:#999;
                        cursor:default;
                    }
                    input[type="checkbox"]:checked ~ .checkbox_label {
                        width:calc(100% - 20px);
                    }
                    input[type="checkbox"]:checked ~ i.fi-x {
                        display:inline;
                        width:20px;
                        font-weight:bold;
                        color:#0168b3;
                    }
                }
            }
            .ez_filterheadline {
                margin:0.9375rem 0 0.5rem 0;
                font-weight:600;
                border-bottom:1px solid $main-primary;
                font-size: 14px;
                label {
                    input[type="checkbox"] ~ .checkbox_label {
                        font-weight:600;
                    }
                }
            }
        }
    }
    // .tab_public_element {
    //     // border: 1px solid #bcbbbb;
    //     .headline {
    //         span {
    //             color: $main-primary;
    //         }
    //     }
    //     .content {
    //         border-top: 1px solid lightgray;
    //     }
    // }
    .ui-tabs-panel {
        border: 1px solid #bcbbbb;
    }
    .ui-widget-content {
        a {
            color: $main-primary;
        }
    }
    .button {
        background-color: $main-primary;
    }
    .ui-state-active {
        background-color: $main-primary !important;
    }
    .svg_icon {
        fill: $main-primary;
    }
    .doyoumean_headline {
        color: #dd4b39;
    }
    .doyoumean_value {
        color: #1a0dab;
    }
    .information_item {
        .headline {
            a {
                color: #1a0dab;
            }
            a:visited {
                color: $main-primary;
            }
            a:hover {
                text-decoration: underline;
            }
        }
        .url {
            a {
                color: green;
                font-size: 0.9rem;
                text-decoration: underline;
            }
        }
        .description {
            color: gray;
        }
        .hit {
            color: $main-primary;
        }
        .keywords .keyword_container .keyword {
            color: #1a0dab;
        }
        .keywords .keyword_container .keyword:visited {
            color: #609;
        }
    }
    .old_price {
        height:10px;
        color:#000;
        font-size:13px;
        line-height:10px;
        padding-left:30px;
        text-decoration:line-through;
    }
    .new_price {
        color: #cd071e;
    }
    #tab-information {
        .information_item {
            margin-bottom: 15px;
            .headline {
                a {
                    color: $main-primary;
                }
            }
            .description {
                padding-left: 15px;
                margin: 0;
                font-size: 0.8rem;
            }
        }
    }
    #next_button {
        margin-top: 15px;
    }
}

//********** To Top **********//
#to_top {
    background-color: $main-primary;
    color: white;
}

//********** Message Box **********//
#header_message_box_outer {
    background-color: #acc9da;
}

#header_message_box_inner {
    border-left: 6px solid orange;
}

//********** Cookie Directive **********//
[data-ez-module-cookiedirective] .options li {
    label {
        padding: 15px 5px;
        line-height: 20px;
    }
}

[data-ez-module-cookiedirective] .options li:hover {
    background-color: #f2f2f2;
}

[data-ez-module-cookiedirective] .options li:hover,
[data-ez-module-cookiedirective] .options li label:hover {
    cursor: pointer;
}

// FORM VALIDATOR
@mixin helper-valid( $helper-name) {
    .password_checker[data-ez-#{$helper-name}="true"] .helper .helper-item.#{$helper-name} {
        color: #28a745;
    }
    .password_checker[data-ez-#{$helper-name}="true"] .helper .helper-item.#{$helper-name} .icon.valid-status:before {
        @include icon-password-strength-status( true);
    }
}

@mixin icon-password-strength-status( $status) {
    content: "\f00c";
    @if $status {
        color: #28a745;
    }
    @else {
        color: gray;
    }
}

@mixin icon-input-status( $status) {
    @if $status=="valid" {
        content: "\f00c";
        color: #28a745;
    }
    @else if $status=="invalid" {
        content: "\f00d";
        color: #dc3545;
    }
    @else if $status=="required" {}
    @else if $status=="wait" {
        content: "\f110";
        color: gray;
        @include play-animation( rotate,
        1s,
        infinite);
    }
}

[data-ez-open="true"] {
    display: block !important;
}

[data-ez-open="false"] {
    display: none !important;
}

[data-ez-module-formvalidator] {
    .form_item {
        * {
            max-width: 100%;
            margin-left: 0px !important;
            margin-right: 0px !important;
        }
        input,
        select {
            margin: 0px;
            padding-bottom: 0px;
            padding-top: 0px;
            padding-left: 15px;
            border: none;
            outline: 0px;
            box-shadow: none;
            color: black;
            background-color: transparent;
            height: 35px;
            min-width: 2rem;
        }
        // Auf checkboxen und radioboxen kann kein padding gesetzt werden
        input[type="checkbox"],
        input[type="radio"] {
            margin: 8px 0 8px 15px !important;
            height: 20px;
        }
        select {
            -webkit-appearance: menulist-button;
        }
        input:focus,
        input:hover,
        select:focus,
        select:hover {
            background-color: transparent;
        }
        input[disabled],
        select[disabled] {
            background-color: #e8e6e6;
        }
        input[disabled]:hover,
        select[disabled]:hover {
            cursor: not-allowed;
        }
        .container {
            @include display-flex;
            margin-bottom: 1.2rem;
            justify-content: center;
            border: 1px solid gray;
            border-radius: $border-radius;
            input[type=text] {
                border-radius: $border-radius;
            }
            .status {
                float: right;
                width: 30px;
                border-top-right-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
                label {
                    position: relative;
                    svg {
                        fill: #EB7E2D;
                    }
                }
            }
            &.noborder {
                border: 1px solid white;
            }
        }
        .feedback {
            position: absolute;
            display: none;
            height: 16px;
            top: 37px;
            left: 15px;
            font-size: 12px;
            color: gray;
            pointer-events: none;
        }
        .feedback.valid {
            display: none;
        }
        .icon {
            @include display-flex;
            float: right;
            justify-content: center;
            margin: 0px;
            width: 100%;
            height: 100%;
            align-items: center;
        }
        .icon.valid-status[data-valid-status="required"]:before {
            @include icon-input-status( "required");
        }
        .icon:before {
            font-size: 14px;
        }
    }
    .form_item[data-ez-valid="false"] {
        .feedback.valid {
            display: none;
        }
        .feedback.invalid {
            display: block;
        }
        .icon.valid-status:before {
            @include icon-input-status( "invalid");
        }
        .container {
            border-color: #dc3545;
        }
        .feedback {
            display: block;
        }
    }
    .form_item[data-ez-valid="true"] {
        .feedback.valid {
            display: block;
            padding-left: 1.2em;
        }
        .feedback.invalid {
            display: none;
        }
        .icon.valid-status:before {
            @include icon-input-status( "valid");
        }
        .container {
            border-color: #28a745;
        }
        .mail-validation-message {
            display: none !important;
        }
    }
    .form_item[data-ez-valid] {
        .feedback.valid {
            color: #28a745;
        }
        .feedback.invalid {
            color: #dc3545;
        }
    }
    .form_item {
        .icon.valid-status[data-valid-status="wait"]:before {
            @include icon-input-status( "wait");
        }
    }
}

.password_checker {
    position: relative;
    .output_bar {
        height: 10px;
    }
    .helper {
        display: none;
        position: absolute;
        background-color: #f1f1f1;
        border: 1px solid lightgray;
        padding: 20px;
        z-index: 1;
        left: 0px;
        right: 0px;
        top: calc( 100% + 10px);
        font-size: 14.4px;
        ul {
            font-size: 14.4px;
            margin: 0px;
            list-style: none;
            li {
                position: relative;
            }
        }
    }
}

.password_checker .helper .helper-item {
    color: gray;
}

.password_checker .helper .helper-item .icon.valid-status:before {
    @include icon-password-strength-status( false);
}

.password_checker input[data-ez-input]:focus+.helper {
    display: block;
}

@include helper-valid( min-length);
@include helper-valid( max-length);
@include helper-valid( repeated-chars);

/* ##### stop: MODULES ##### */