﻿/* ezentrum_variables koennen im alten SCSS System dieses Shops nicht verwendet werden */
/*@use "../ezentrum_variables";*/

[data-ez-module-formvalidator] .ez_input {
	position:relative;
	input[type="text"],
	input[type="number"],
	input[type="email"],
	input[type="password"],
	input[type="tel"] {
/*		color:ezentrum_variables.$body-font-color;*/
		&:read-only {
/*			color: ezentrum_variables.$gray;*/
			color:grey;
			cursor:default;
		}
	}
	.ez_status {
		position:absolute;
		top:8px;
/*		right:2 * ezentrum_variables.$global-margin;*/
		right:1.6rem;
	}
}

[data-ez-module-formvalidator] .form_item {
	.false,
	.true {
		display:none;
	}
}
[data-ez-module-formvalidator] .form_item[data-ez-valid="false"] {
	.ez_icon.false {
		display:block;
		border-radius:50%;
/*		color:ezentrum_variables.$alert-color;
		background-color: ezentrum_variables.$white;*/
		color:red;
		background-color:white;
	}
	.ez_icon.true {
		display:none;
	}
	label.ez_checkboxlabel {
		position:relative;
		margin:8px 0 8px 15px;
		height:20px;
		&:before {
			position:absolute;
			right:6px;
			content:"";
			width:20px;
			height:20px;
			background-color: white;
			border: 2px solid red;
/*			border-radius: ezentrum_variables.$global-radius;*/
		}
		input[type="checkbox"] {
			display:none;
		}
	}
}

[data-ez-module-formvalidator] .form_item[data-ez-valid="true"] {
	.ez_icon.false {
		display:none;
	}
	.ez_icon.true {
		display:block;
		border-radius:50%;
/*		color:ezentrum_variables.$success-color;
		background-color: ezentrum_variables.$white;*/
		color:green;
		background-color:white;
	}
	label.ez_checkboxlabel {
		position:relative;
		margin:8px 0 8px 15px;
		height:20px;
		&:before {
			position:absolute;
			right:6px;
			padding:2px;
			content:"✔︎";
			width:20px;
			height:20px;
			font-size: 12px;
			line-height: 12px;
			color: white;
			background-color: green;
			border: 2px solid green;
/*			border-radius: ezentrum_variables.$global-radius;*/
		}
		input[type="checkbox"] {
			display:none;
		}
	}
}

[data-ez-module-formvalidator] .form_item[data-ez-valid] .feedback.invalid {
/*	color:ezentrum_variables.$alert-color;*/
	color:red;
}

[data-ez-module-formvalidator] .form_item[data-ez-valid="false"] .icon.valid-status::before {
  content: "";
}
[data-ez-module-formvalidator] .form_item[data-ez-valid="true"] .icon.valid-status::before {
  content: "";
}
