/* lato-100 - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lato';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/lato-v23-latin-100.eot');
    /* IE9 Compat Modes */
    src: url('../fonts/lato-v23-latin-100.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/lato-v23-latin-100.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/lato-v23-latin-100.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/lato-v23-latin-100.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/lato-v23-latin-100.svg#Lato') format('svg');
    /* Legacy iOS */
}

/* lato-300 - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/lato-v23-latin-300.eot');
    /* IE9 Compat Modes */
    src: url('../fonts/lato-v23-latin-300.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/lato-v23-latin-300.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/lato-v23-latin-300.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/lato-v23-latin-300.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/lato-v23-latin-300.svg#Lato') format('svg');
    /* Legacy iOS */
}

/* lato-regular - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/lato-v23-latin-regular.eot');
    /* IE9 Compat Modes */
    src: url('../fonts/lato-v23-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/lato-v23-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/lato-v23-latin-regular.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/lato-v23-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/lato-v23-latin-regular.svg#Lato') format('svg');
    /* Legacy iOS */
}

/* lato-700 - latin */
@font-face {
    font-display: swap;
    /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/lato-v23-latin-700.eot');
    /* IE9 Compat Modes */
    src: url('../fonts/lato-v23-latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('../fonts/lato-v23-latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('../fonts/lato-v23-latin-700.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/lato-v23-latin-700.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('../fonts/lato-v23-latin-700.svg#Lato') format('svg');
    /* Legacy iOS */
}